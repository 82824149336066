import { mergeDeepRight } from 'ramda';
import {
  ICountry,
  ICountry_Search_Radius,
  ICountry_Supplier,
} from '@bridebook/models/source/models/Countries.types';
import { env } from '@bridebook/toolbox/src/env';
import { imgixBaseURL } from '../buildImgixUrl';
import GazetteerDev from './data/gazetteer.dev.json';
import GazetteerProd from './data/gazetteer.prod.json';

export const defaultLocale: string = 'en';

/**
 * Cherry-picked from `ICountry` type for the purposes of the gazetteer.
 */
export type Country = {
  alias?: ICountry['alias'];
  areas: Required<ICountry>['areas'];
  config: ICountry['config'];
  continent: Required<ICountry>['continent'];
  currency: Required<ICountry>['currency'];
  flags: NonNullable<ICountry['flags']>;
  id: CountryCodes;
  locales: Required<ICountry>['locales'];
  mappings: ICountry['mappings'] & {
    search: {
      radius: ICountry_Search_Radius;
    };
  };
  suppliers: NonNullable<ICountry['suppliers']>;
};

export enum CountryCodes {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

type MarketKey = Lowercase<Country['id'] | NonNullable<Country['alias']>>;

export class Gazetteer {
  readonly #data: Partial<Record<MarketKey, Country>> = {};

  constructor(countries: Country[]) {
    const fallback = countries.find((country) => country.id === ('*' as CountryCodes)) ?? {};

    for (const country of countries) {
      if (country.id === ('*' as CountryCodes)) {
        continue;
      }

      /**
       * Prebuild country/alias market keys for faster lookups.
       */
      const keys: [MarketKey, MarketKey?] = [
        country.id.toLowerCase() as MarketKey,
        country.alias?.toLowerCase() as MarketKey | undefined,
      ];

      /**
       * Merge default values from fallback (`*`) country.
       */
      const value: Country = mergeDeepRight(fallback, country);

      for (const key of keys) {
        if (key == null) {
          continue;
        }

        this.#data[key] = value;
      }
    }
  }

  /**
   * Returns all countries that match the provided flags.
   *
   * @param flags Optional flags to filter by.
   * @returns All filtered countries.
   */
  getCountries(flags?: Partial<Country['flags']>): Country[] {
    const result = new Set<Country>();

    for (const country of Object.values(this.#data)) {
      result.add(country);
    }

    if (flags != null) {
      return [...result].filter((country) => {
        for (const [key, value] of Object.entries(flags)) {
          const index = key as keyof Country['flags'];

          if (country.flags[index] !== value) {
            return false;
          }
        }

        return true;
      });
    }

    return [...result];
  }

  /**
   * Returns an Emoji flag for the country.
   * Note that Emoji flags are not rendered correctly on Windows.
   *
   * @param country The country code.
   * @returns The emoji flag.
   */
  static getCountryFlagEmoji(country: CountryCodes | 'EU'): string {
    return country
      .split('')
      .map((value) => String.fromCodePoint(0x1f1a5 + value.charCodeAt(0)))
      .join('');
  }

  /**
   * Returns an URL to a flag image for the country.
   * The original files were downloaded from https://flagcdn.com/.
   *
   * @param country The country code.
   * @returns The URL to the flag image.
   */
  static getCountryFlagImageURL(country: CountryCodes | 'EU'): string | undefined {
    if (Object.keys(CountryCodes).concat('EU').includes(country) !== true) {
      return undefined;
    }

    return `${imgixBaseURL}/assets/flags/160x120/${country.toLowerCase()}.png?v=wavy`;
  }

  /**
   * Uses the `Intl` API to derive the country name from the country code.
   *
   * @param country The country code.
   * @param locale The locale to use for the country name.
   * @returns The country name.
   */
  static getCountryName(country: CountryCodes, locale = defaultLocale): string {
    return new Intl.DisplayNames(locale, { type: 'region' }).of(country) ?? '';
  }

  /**
   * Uses the `Intl` API to derive the currency name from the currency code.
   * This is useful for when we need to display the currency name in the UI.
   *
   * @param currency The currency code.
   * @param locale The locale to use for the currency name.
   * @returns The currency name.
   */
  static getCurrencyName(currency: string, locale = defaultLocale): string {
    const result = new Intl.DisplayNames(locale, { type: 'currency' }).of(currency) ?? '';
    const segments = [];

    /**
     * Intl.Segmenter is not supported in the current Firefox release.
     */
    // @ts-expect-error - Missing type definition.
    if (Intl.Segmenter === undefined) {
      for (const segment of result.split(' ')) {
        segments.push(segment.charAt(0).toLocaleUpperCase(locale) + segment.slice(1));
      }

      return segments.join(' ');
    }

    // @ts-expect-error - Missing type definition.
    const segmenter = new Intl.Segmenter(locale, { granularity: 'word' }).segment(result);

    for (const segment of segmenter) {
      let token = segment.segment as string;

      if (segment.isWordLike === true) {
        token = token.charAt(0).toLocaleUpperCase(locale) + token.slice(1);
      }

      segments.push(token);
    }

    return segments.join('');
  }

  /**
   * Uses the `Intl` API to derive the currency symbol from the currency code.
   *
   * @param currency The currency code.
   * @param locale The locale to use for the currency symbol.
   * @returns The currency symbol.
   */
  static getCurrencySymbol(currency: string, locale = defaultLocale): string {
    const result = new Intl.NumberFormat(locale, {
      currency,
      currencyDisplay: 'symbol',
      style: 'currency',
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency');

    if (result != null) {
      return result.value;
    }

    return '';
  }

  /**
   * Uses the `Intl` API to derive the language name from the language code.
   *
   * @param language The language code.
   * @param locale The locale to use for the language name.
   * @returns The language name.
   */
  static getLanguageName(language: string, locale = defaultLocale): string {
    const result = new Intl.DisplayNames(locale, { type: 'language' }).of(language) ?? '';
    const segments = [];

    /**
     * Intl.Segmenter is not supported in the current Firefox release.
     */
    // @ts-expect-error - Missing type definition.
    if (Intl.Segmenter === undefined) {
      for (const segment of result.split(' ')) {
        segments.push(segment.charAt(0).toLocaleUpperCase(locale) + segment.slice(1));
      }

      return segments.join(' ');
    }

    // @ts-expect-error - Missing type definition.
    const segmenter = new Intl.Segmenter(locale, { granularity: 'word' }).segment(result);

    for (const segment of segmenter) {
      let token = segment.segment as string;

      if (segment.isWordLike === true) {
        token = token.charAt(0).toLocaleUpperCase(locale) + token.slice(1);
      }

      segments.push(token);
    }

    return segments.join('');
  }

  /**
   * Instantiates a new `Market` object from the given country code.
   *
   * @param country The country code.
   * @param fallback Optional country code to fallback to.
   * @returns The market object.
   */
  getMarketByCountry(country: CountryCodes, fallback?: CountryCodes | null): Market {
    try {
      const key = country.toLowerCase() as MarketKey;

      /**
       * We need to check if the country code matches because we also support aliased market keys.
       */
      if (this.#data[key]?.id === country) {
        return new Market(this.#data[key] as Country);
      }

      throw new Error(`Unable to derive market from ${country}.`);
    } catch (error) {
      if (fallback == null) {
        throw error;
      }

      return this.getMarketByCountry(fallback);
    }
  }

  /**
   * Instantiates a new `Market` object from the given URL segment.
   *
   * @param url A URL or segment with a alias or market-language[-country] segment.
   * @param fallback Optional country code to fallback to.
   * @returns The market object.
   */
  getMarketByURL(url?: URL | string, fallback?: CountryCodes | null): Market {
    try {
      if (url == null) {
        throw new Error('Unable to derive market from empty segment.');
      } else if (url instanceof URL) {
        url = url.pathname;
      } else if (/^https?:[/][/]/i.test(url) === true) {
        url = new URL(url).pathname;
      }

      url = url.split('/').find((value) => value.length > 0);

      if (url == null || url === '') {
        throw new Error('Unable to derive market from empty segment.');
      }

      const segments = url.split('-');

      /**
       * If the segment consists of a single token, it must be an alias.
       * This logic supports our legacy markets (GB, DE, FR, IE).
       */
      if (segments.length === 1) {
        const key = url.toLowerCase() as MarketKey;

        if (this.#data[key]?.alias === key) {
          return new Market(this.#data[key] as Country);
        }

        throw new Error(`Unable to derive market from ${url}.`);
      }

      /**
       * The first segment specifies the market, and the second specifies the language.
       * The third segment is optional, and specifies the language-country variant.
       */
      const [market, language, variant] = [
        segments.shift()?.toUpperCase() as CountryCodes,
        segments.shift(),
        segments.shift()?.toUpperCase() as CountryCodes | undefined,
      ];

      const key = market.toLowerCase() as Lowercase<CountryCodes>;
      const candidate = this.#data[key];

      /**
       * If we can't locate a country by the pre-computed country/alias market key then we should bail.
       */
      if (candidate == null || (candidate.alias ?? candidate.id).toUpperCase() !== market) {
        throw new Error(`Unable to derive market from ${url}.`);
      }

      const locale = [language, variant].filter((value) => value != null).join('-');
      const locales = candidate.alias != null ? candidate.locales.slice(1) : candidate.locales;

      if (locales.some((value) => value.locale === locale) !== true) {
        throw new Error(`Locale ${locale} is not supported for ${candidate.id} market.`);
      }

      return new Market(candidate, locale);
    } catch (error) {
      if (fallback == null) {
        throw error;
      }

      return this.getMarketByCountry(fallback);
    }
  }

  /**
   * Returns all markets that match the provided flags.
   *
   * @param flags Optional flags to filter by.
   * @returns All filtered markets.
   */
  getMarkets(flags?: Partial<Country['flags']>): Market[] {
    return this.getCountries(flags).map((country) => new Market(country));
  }

  /**
   * Validates if given string is proper country code.
   *
   * @param value A value to check.
   * @returns Whether the value is a valid country code.
   */
  static isValidCountryCode(value?: string): value is CountryCodes {
    return Object.values(CountryCodes).includes(value as CountryCodes);
  }
}

export class Market {
  #data: Country;
  #locale?: string;
  #currency?: string;

  constructor(data: Country, locale?: string) {
    this.#data = data;
    this.#locale = locale;
  }

  /**
   * Returns the alias URL prefix that serves this market, if any.
   */
  get alias() {
    return this.#data.alias;
  }

  /**
   * Returns the list of admin area indexes for this market, ordered by most to least specific.
   */
  get areas(): Array<number> {
    return this.#data.areas;
  }

  get config() {
    return this.#data.config ?? {};
  }

  get country() {
    return this.#data.id;
  }

  get currency() {
    return this.#currency ?? this.#data.currency;
  }

  set currency(currency: string) {
    this.#currency = currency;
  }

  get flags() {
    return this.#data.flags;
  }

  get hasAnyDirectory() {
    return this.suppliers.length > 0;
  }

  get hasFullDirectory() {
    return this.suppliers.includes('venue') && this.suppliers.length > 1;
  }

  get hasPostalCode() {
    return this.config.postalCode !== undefined;
  }

  get hasVenueDirectory() {
    return this.suppliers.includes('venue');
  }

  get hasSupplierDirectory() {
    return this.suppliers.some((supplier) => supplier !== 'venue');
  }

  /**
   * Returns the base language for the current locale.
   * For example, the `en-GB` locale would return `en`.
   */
  get language() {
    return this.locale.split('-')[0];
  }

  /**
   * Returns the base languages for all locale.
   */
  get languages() {
    return [...new Set(this.locales.map((locale) => locale.split('-')[0]))];
  }

  /**
   * Returns the current locale or the default market locale if none is set.
   */
  get locale() {
    return this.#locale ?? this.locales[0];
  }

  /**
   * Sets the current locale.
   * This is useful for when user preferences are available.
   */
  set locale(locale: string) {
    this.#locale = locale;
  }

  /**
   * Returns the list of locales supported by this market, sorted by weight.
   */
  get locales() {
    return this.#data.locales.map((value) => value.locale);
  }

  /**
   * Returns the mappings for this market.
   */
  get mappings() {
    return this.#data.mappings;
  }

  /**
   * Returns the canonical URL prefix that serves this market in the most specific locale allowed.
   */
  get prefix() {
    /**
     * If an alias is defined for this market, we should always use it.
     */
    if (this.#data.alias != null) {
      /**
       * If the current locale is supported and it's not the default for this market, we should return it.
       */
      if (this.locales.slice(1).includes(this.locale) === true) {
        return `${this.#data.alias}-${this.locale}`.toLowerCase();
      }

      return this.#data.alias;
    }

    /**
     * If the current locale is not supported in this market, we should return the default country locale instead.
     */
    if (this.locales.includes(this.locale) !== true) {
      return `${this.country}-${this.locales[0]}`.toLowerCase();
    }

    return `${this.country}-${this.locale}`.toLowerCase();
  }

  /**
   * Returns which supplier types are available in the directory for this market.
   */
  get suppliers() {
    return this.#data.suppliers || [];
  }

  /**
   * Returns an Emoji flag for the country.
   * Note that Emoji flags are not rendered correctly on Windows.
   * By default the country of the current market will be used.
   *
   * @param country The country code.
   * @returns The emoji flag.
   */
  getCountryFlagEmoji(country: CountryCodes | 'EU' = this.country) {
    return Gazetteer.getCountryFlagEmoji(country);
  }

  /**
   * Returns an URL to a flag image for the country.
   * The original files were downloaded from https://flagcdn.com/.
   * By default the country of the current market will be used.
   *
   * @param country The country code.
   * @returns The URL to the flag image.
   */
  getCountryFlagImageURL(country: CountryCodes | 'EU' = this.country): string | undefined {
    return Gazetteer.getCountryFlagImageURL(country);
  }

  /**
   * Uses the `Intl` API to derive the country name from the country code.
   * By default the country of the current market will be used.
   *
   * @param country The country code.
   * @returns The country name.
   */
  getCountryName(country: CountryCodes = this.country): string {
    return Gazetteer.getCountryName(country, this.locale);
  }

  /**
   * Uses the `Intl` API to derive the currency name from the currency code.
   * This is useful for when we need to display the currency name in the UI.
   * By default the currency of the current market will be used.
   *
   * @param currency The currency code.
   * @returns The currency name.
   */
  getCurrencyName(currency: string = this.currency): string {
    return Gazetteer.getCurrencyName(currency, this.locale);
  }

  /**
   * Uses the `Intl` API to derive the currency symbol using the current locale.
   * By default the currency of the current market will be used.
   *
   * @param currency The currency code.
   * @returns The currency symbol.
   */
  getCurrencySymbol(currency: string = this.currency) {
    return Gazetteer.getCurrencySymbol(currency, this.locale);
  }

  /**
   * Uses the `Intl` API to derive the language name from the language code.
   * By default the language of the current market will be used.
   *
   * @param language The language code.
   * @returns The language name.
   */
  getLanguageName(language: string = this.language): string {
    return Gazetteer.getLanguageName(language, this.locale);
  }

  /**
   * Returns flags for the specified locale.
   *
   * @param locale The locale to fetch flags from, defaults to the current market locale.
   * @returns The flags for the specified locale.
   */
  getLocaleFlags(locale: string = this.locale) {
    return this.#data.locales.find((value) => value.locale === locale)?.flags;
  }

  /**
   * Formats a number into a currency string using the current locale.
   * By default the currency of the current market will be used.
   *
   * @param value The number to format.
   * @param options The options to customize the number formatting.
   * @returns The formatted currency string.
   */
  formatCurrency(value: number, options?: Intl.NumberFormatOptions): string {
    return value
      .toLocaleString(this.locale, {
        ...options,
        currency: options?.currency ?? this.currency,
        style: 'currency',
      })
      .replace(/\s/g, ' ');
  }

  /**
   * Formats a date using the current locale.
   *
   * @param value The date to format.
   * @param options The options to customize the date formatting.
   * @returns The formatted date string.
   */
  formatDate(value: Date, options?: Intl.DateTimeFormatOptions): string {
    return value
      .toLocaleDateString(this.locale, {
        ...options,
      })
      .replace(/\s/g, ' ');
  }

  /**
   * Formats a list of strings using the current locale.
   *
   * @param values The list of strings to format.
   * @param options The options to customize the list formatting.
   * @returns The formatted list string.
   */
  formatList(
    values: string[],
    options?: { type: 'conjunction' | 'disjunction' | 'unit'; style: 'long' | 'short' | 'narrow' },
  ): string {
    // @ts-expect-error - Missing type definition.
    return new Intl.ListFormat(this.locale, options).format(values).replace(/\s/g, ' ');
  }

  /**
   * Formats a list of strings using the current locale and "and"-based grouping.
   *
   * @param values The list of strings to format.
   * @returns The formatted list string.
   */
  formatListConjunction(values: string[]) {
    return this.formatList(values, { type: 'conjunction', style: 'long' }).replace(/\s/g, ' ');
  }

  /**
   * Formats a list of strings using the current locale and "or"-based grouping.
   *
   * @param values The list of strings to format.
   * @returns The formatted list string.
   */
  formatListDisjunction(values: string[]) {
    return this.formatList(values, { type: 'disjunction', style: 'long' }).replace(/\s/g, ' ');
  }

  /**
   * Formats a date using the current locale.
   *
   * @param value The date to format.
   * @param options The options to customize the date/time formatting.
   * @returns The formatted date/time string.
   */
  formatDateTime(value: Date, options?: Intl.DateTimeFormatOptions): string {
    return value
      .toLocaleString(this.locale, {
        ...options,
      })
      .replace(/\s/g, ' ');
  }

  /**
   * Formats a number using the current locale.
   *
   * @param value The number to format.
   * @param options The options to customize the number formatting.
   * @returns The formatted number string.
   */
  formatNumber(value: number, options?: Intl.NumberFormatOptions): string {
    return value
      .toLocaleString(this.locale, {
        ...options,
      })
      .replace(/\s/g, ' ');
  }

  /**
   * Formats a number into an ordinal string using the current locale.
   *
   * Returns `one` if it should have the english ordinal suffix `st`.
   * Returns `two` if it should have the english ordinal suffix `nd`.
   * Returns `few` if it should have the english ordinal suffix `rd`.
   * Returns `other` if it should have the english ordinal suffix `th`.
   *
   * @param value The number to format.
   * @param options The options to customize the ordinal formatting.
   * @returns The ordinal suffix string.
   */
  formatOrdinal(value: number, options?: Intl.PluralRulesOptions): string {
    return new Intl.PluralRules(this.locale, {
      ...options,
      type: 'ordinal',
    })
      .select(value)
      .replace(/\s/g, ' ');
  }

  /**
   * Formats a date using the current locale.
   *
   * @param value The date to format.
   * @param options The options to customize the time formatting.
   * @returns The formatted time string.
   */
  formatTime(value: Date, options?: Intl.DateTimeFormatOptions): string {
    return value
      .toLocaleTimeString(this.locale, {
        ...options,
      })
      .replace(/\s/g, ' ');
  }

  /**
   * Returns if the market has the given supplier category in directory.
   *
   * @param category The category to check for presence.
   * @returns True if the market has the supplier category, false otherwise.
   */
  hasSupplierCategory(category: ICountry_Supplier) {
    return this.suppliers.includes(category);
  }

  /**
   * Validates if given string is a valid postal code for the country.
   *
   * @param value The postal code to validate.
   * @returns Whether the postal code is valid, or `null` if the country does not have postal codes.
   */
  isValidPostalCode(value: string): boolean | null {
    if (this.config.postalCode === undefined) {
      return null;
    }

    return new RegExp(`^(?:${this.config.postalCode})$`).test(value);
  }

  /**
   * Rearranges the list of non-null `adminAreas` according to the order defined by the market.
   *
   * @param adminAreas The list of admin areas, from the standard Address struct.
   * @param limit The maximum number of admin areas to return, defaults to all.
   * @returns The list of admin areas in the expected order (most to least specific).
   */
  sortAdminAreas(adminAreas: string[], limit = Infinity) {
    const result: string[] = [];

    for (const index of this.areas) {
      if (adminAreas.at(index) != null && adminAreas.at(index) !== '') {
        result.push(adminAreas.at(index) as string);

        if (--limit === 0) {
          break;
        }
      }
    }

    return result;
  }

  /**
   * @returns {string} JSON representation of the market object.
   */
  toJSON(): Partial<Market> {
    return {
      country: this.country,
      currency: this.currency,
      language: this.language,
      locale: this.locale,
      locales: this.locales,
      prefix: this.prefix,
    };
  }

  /**
   * @returns {string} A string representation of the market object.
   */
  toString(): string {
    return `${this.country}-${this.currency}-${this.language}-${this.locale}-${this.prefix}`;
  }
}

/**
 * Default Gazetteer instance.
 * This is to avoid having to load the JSON multiple times.
 *
 * @TODO: Figure out a better way to load the appropriate JSON dump.
 */
export default new Gazetteer((env.LIVE ? GazetteerProd : GazetteerDev) as unknown as Country[]);
